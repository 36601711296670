'use client'
// React
import { ReactNode, useState } from 'react'
// React Auth
import { SessionProvider } from 'next-auth/react'
// Sonner
import { Toaster } from 'sonner'
// Tooltip
import { TooltipProvider } from '@/_components/ui/tooltip'
// React Query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
// React dnd
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

interface Props {
  children: ReactNode
}

function Providers({ children }: Props) {
  const [queryClient] = useState(() => new QueryClient())

  return (
    <TooltipProvider delayDuration={0}>
      <DndProvider backend={HTML5Backend}>
        <Toaster toastOptions={{}} position="top-right" richColors />
        <QueryClientProvider client={queryClient}>
          <SessionProvider>{children}</SessionProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </DndProvider>
    </TooltipProvider>
  )
}

export default Providers
